export const regionOptions = [
  '선택하세요.',
  '강원도',
  '경기도',
  '경상도',
  '서울',
  '전라도',
  '제주도',
  '충청도',
];

export const genderOptions = [
  '선택하세요.',
  '남',
  '여',
  '공개 안함',
  '사용자 지정',
];
